// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("/codebuild/output/src3572789181/src/cfs-website/node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-templates-page-js": () => import("/codebuild/output/src3572789181/src/cfs-website/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-news-and-media-js": () => import("/codebuild/output/src3572789181/src/cfs-website/src/templates/newsAndMedia.js" /* webpackChunkName: "component---src-templates-news-and-media-js" */),
  "component---src-templates-press-js": () => import("/codebuild/output/src3572789181/src/cfs-website/src/templates/press.js" /* webpackChunkName: "component---src-templates-press-js" */),
  "component---src-templates-devens-campus-events-js": () => import("/codebuild/output/src3572789181/src/cfs-website/src/templates/devens-campus/events.js" /* webpackChunkName: "component---src-templates-devens-campus-events-js" */),
  "component---src-templates-devens-campus-public-benefits-js": () => import("/codebuild/output/src3572789181/src/cfs-website/src/templates/devens-campus/public-benefits.js" /* webpackChunkName: "component---src-templates-devens-campus-public-benefits-js" */),
  "component---src-templates-devens-campus-timeline-js": () => import("/codebuild/output/src3572789181/src/cfs-website/src/templates/devens-campus/timeline.js" /* webpackChunkName: "component---src-templates-devens-campus-timeline-js" */),
  "component---src-templates-devens-campus-updates-js": () => import("/codebuild/output/src3572789181/src/cfs-website/src/templates/devens-campus/updates.js" /* webpackChunkName: "component---src-templates-devens-campus-updates-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src3572789181/src/cfs-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src3572789181/src/cfs-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-and-media-tag-js": () => import("/codebuild/output/src3572789181/src/cfs-website/src/pages/news-and-media/tag.js" /* webpackChunkName: "component---src-pages-news-and-media-tag-js" */),
  "component---src-pages-page-preview-js": () => import("/codebuild/output/src3572789181/src/cfs-website/src/pages/pagePreview.js" /* webpackChunkName: "component---src-pages-page-preview-js" */),
  "component---src-pages-press-preview-js": () => import("/codebuild/output/src3572789181/src/cfs-website/src/pages/pressPreview.js" /* webpackChunkName: "component---src-pages-press-preview-js" */),
  "component---src-pages-technology-js": () => import("/codebuild/output/src3572789181/src/cfs-website/src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-test-js": () => import("/codebuild/output/src3572789181/src/cfs-website/src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

